* {
	box-sizing: border-box;
}

/* fonts */
h1 {
	font-family: "Press Start 2P", cursive;
	font-size: 3rem;
	text-align: center;
}

#blog-title {
	font-family: "Marck Script", cursive;
}

#modal-title {
	font-family: "Bungee Shade", cursive;
}

/* Landing Page */
.landingImage {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100vw;
	height: 150vh;
	background-image: url("https://images.unsplash.com/reserve/LJIZlzHgQ7WPSh5KVTCB_Typewriter.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2741&q=80");
}

.typewriter {
	text-align: center;
	padding-top: 15vh;
	font-size: xx-large;
	font-family: monospace;
}

/* CV section */
.canva-embed {
	border: 20px solid transparent;
	padding: 15px;
	border-image: url("https://images.unsplash.com/photo-1540206395-68808572332f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60")
		30 round;
}

/* projects section */

.mobile-projects-container {
	display: none;
}

#project-card:hover {
	transform: translate3D(0, -1px, 0) scale(1.03);
}

/* programming logos */
.devicon-rails-plain-wordmark {
	font-size: 10rem;
	position: relative;
	top: 75px;
	left: 80px;
}

.devicon-react-original-wordmark {
	font-size: 10rem;
	float: right;
	position: relative;
	top: 75px;
	right: 80px;
}

.devicon-javascript-plain {
	font-size: 10rem;
	float: right;
	position: relative;
	top: 75px;
	right: 80px;
}

/* page dividers */
hr.dashed {
	border-top: 3px dashed blue;
}

/* blogs section */

.medium-widget-article__row {
	width: 100%;
	margin: 0;
}

@media (max-width: 40rem) {
	.ui.centered.three.column.grid {
		display: none;
	}
	.mobile-projects-container {
		display: block;
	}

	.canva-embed {
		border: 0;
	}
}
